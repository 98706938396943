<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">
          
          <app-filter-apply-button
            :has-changes="filterStore.hasChanges"
            :is-initial-state="filterStore.isInitialState"
            :is-loading="loading"
            :can-refresh="true"
            @click="applyFilter();"
            @reset="filterStore.$reset(); applyFilter();"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  :clearable="true"
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <content-filter-customer
                      :get-search-url="getCustomerSearchUrl"
                      v-model="filterStore.filter.selectedCustomer"
                      :preselected="filterStore.filter.selectedCustomer"
                    ></content-filter-customer>
                  </div>
                </div>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <user-filter-widget
                      v-model="filterStore.filter.selectedUser"
                      :preselected="filterStore.filter.selectedUser"
                      :include-inactive="true"
                    ></user-filter-widget>
                  </div>
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>

          <v-menu offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                v-bind="attrs"
                v-on="on"
                :loading="isProcessing"
                :disbaled="isProcessing"
              >
                <v-icon left>mdi-download</v-icon>
                {{ t('export') }}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item 
                v-for="(item, index) in exportOptions" 
                :key="index" 
                @click="handleExport(item.action)" 
                :disabled="isProcessing"
                :class="{ 'with-divider': index < 3 }"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-if="user && (user.role === 'admin' || user.role === 'coordinator')"
            elevation="0"
            color="primary"
            class="icon-left ml-4"
            :to="{ name: 'TimeCreate' }"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create-new-time-entry') }}
          </v-btn>
        </v-row>

        <v-row v-if="dataLoaded && timelogItems.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="timelogItems"
            :options.sync="options"
            :server-items-length="totalTimelogs"
            :loading="loading"
            class="elevation-0 small-font-size"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [10, 25, 50, 100],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.work_date | momentWeekday }}<br>{{ item.work_date | momentDate }}
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{ item.user.number }}
            </template>

            <template v-slot:[`item.staff_number`]="{ item }">
              {{ item.user.staff_number }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.lastname }}
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.object`]="{ item }">
              {{ item.customer.name }}
            </template>

            <template v-slot:[`item.work_start_time`]="{ item }">
              {{ formatTime(item.work_start_time) }}
            </template>

            <template v-slot:[`item.work_end_time`]="{ item }">
              {{ formatTime(item.work_end_time) }}
            </template>

            <template v-slot:[`item.target_seconds`]="{ item }">
              <span v-if="item.target_seconds > 0">{{ item.target_seconds | momentSecondsToHours }}</span>
            </template>

            <template v-slot:[`item.work_duration_seconds`]="{ item }">
              {{ item.work_duration_seconds | momentSecondsToHours }}
            </template>

            <template v-slot:[`item.total`]="{ item }">
              {{ item.totalDuration | momentSecondsToHours }}
            </template>

            <template v-slot:[`item.target_match`]="{ item }">
              <v-icon v-if="!item.target_match" class="mt-3" small color="error">info</v-icon>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/time/edit', query: { userId: item.user.id, date: item.work_date } }"
                           class="edit-row-table">
                <v-icon v-show="canEdit(item)" small class="edit-row-button">edit</v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import moment from 'moment'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import { useTimelogFilterStore } from '@/stores/TimelogFilterStore'
import { isAdmin } from '@/services/UserService'
import { hasPermission } from '@/services/PermissionService'

export default {
  name: 'TimeIndex',
  components: {
    AppFilterApplyButton,
    ContentFilterCustomer,
    UserFilterWidget,
    toolbar,
    loading,
    AppFilterDatePicker
  },
  setup() {
    const filterStore = useTimelogFilterStore()
    return { filterStore } 
  },
  created() {
    this.getUser()
  },
  data() {
    return {
      loading: false,
      dataLoaded: false,
      totalTimelogs: 0,
      timelogItems: [],
      options: {},
      config: {
        record_mode: '',
        external_hours: false,
      },
      user: null,
      isProcessing: false,
      exportOptions: [
        { label: this.t('export-options.excel'), action: "excel" },
        { label: this.t('export-options.day-view'), action: "dayView" },
        { label: this.t('export-options.range'), action: "range" },
        { label: this.t('export-options.customer-day-view'), action: "customerDayView" }
      ],
    };
  },
  async mounted() {
    await this.loadConfiguration()
  },
  computed: {
    headers() {
      let columns = []
      columns.push(
        {
          text: this.t('table.date'),
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: this.t('table.number'),
          value: 'number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.staff_number'),
          value: 'staff_number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.lastname'),
          value: 'lastname',
          sortable: true,
          width: 200,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.firstname'),
          value: 'firstname',
          sortable: true,
          width: 200,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.object'),
          value: 'object',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.customer_project'),
          value: 'customer_project',
          sortable: false,
          cellClass: 'text-middle'
        }
      )

      if (this.config.record_mode !== 'hours') {
        columns.push(
          {
            text: this.t('table.start'),
            align: 'end',
            value: 'work_start_time',
            sortable: false
          },
          {
            text: this.t('table.end'),
            align: 'end',
            value: 'work_end_time',
            sortable: false
          }
        )
      }

      if (this.config.external_hours) {
        columns.push(
          {
            text: this.t('table.target-hours'),
            align: 'end',
            value: 'target_seconds',
            sortable: false
          }
        )
      }

      columns.push(
        {
          text: this.t('table.time'),
          align: 'end',
          value: 'work_duration_seconds',
          sortable: false
        }
      )

      if (this.config.external_hours) {
        columns.push(
          {
            text: this.t('table.discrepancy'),
            value: 'target_match',
            sortable: false
          }
        )
      }

      columns.push(
        {
          text: this.t('table.edit'),
          align: 'center',
          value: 'edit',
          sortable: false,
          cellClass: 'text-middle'
        }
      )

      return columns
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    },
    momentDateTime: function (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    momentWeekday: function (date) {
      return moment(date).locale('de').format('dddd')
    },
    momentSecondsToHours: function (seconds) {
      return (Math.round((seconds / 3600) * 100) / 100).toFixed(2)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-timelogs.list.' + key)
    },
    canEdit: function (item) {
      if (['his', 'tra', 'tri'].includes(store.get('theme'))) {
        return hasPermission(['timelog_admin']) || this.isAdmin()
      } else {
        return (hasPermission(['timelog_admin']) || isAdmin()) && item.can_edit
      }
    },
    getUser() {
      this.user = store.get('user')
    },
    async loadConfiguration() {
      let response = await HTTP.get('timelog/settings')
      this.config.record_mode = response.data.record_mode
      this.config.external_hours = response.data.external_hours
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    reload() {
      this.$nextTick(() => {
        this.timelogItems = []
        this.getDataFromApi()
      })
    },
    async getDataFromApi() {
      if (!this.loading) {
        this.loading = true
        this.dataLoaded = false

        try {
          await this.apiCall()
          this.dataLoaded = true
        } finally {
          this.loading = false
        }
      }
    },
    async apiCall() {
      let filter = this.buildFilter()
      let response = await HTTP.get('timelog', { params: filter })
      this.timelogItems = response.data.data
      this.totalTimelogs = response.data.total
    },
    getCustomerSearchUrl(val) {
      return 'customers?q=' + val
    },
    async handleExport(action) {
      if (!action || this.isProcessing) {
        return
      }

      this.isProcessing = true

      try {
        let filter = this.buildFilter()
        let endpoint = this.getEndpoint(action)
        let response = await HTTP.post(endpoint, filter, { responseType: "arraybuffer" })
        let filename = this.getFilename(action)
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } catch (error) {
        console.error("Export fehlgeschlagen:", error)
      } finally {
        this.isProcessing = false
      }
    },
    buildFilter() {
      let filter = {}
      if (this.filterStore.filter.dateStart) {
        filter.after = this.filterStore.filter.dateStart
      }
      if (this.filterStore.filter.dateEnd) {
        let dateEnd = new Date(this.filterStore.filter.dateEnd)
        dateEnd.setDate(dateEnd.getDate() + 1)
        filter.before = dateEnd.toISOString().split("T")[0]
      }
      if (this.filterStore.filter.selectedUser) {
        filter.q = this.filterStore.filter.selectedUser?.id
      }
      if (this.filterStore.filter.selectedCustomer) {
        filter.c = this.filterStore.filter.selectedCustomer?.id
      }
      return filter
    },
    getEndpoint(action) {
      const endpoints = {
        excel: "dataexport/excel/timelogs",
        dayView: "dataexport/excel/timelogs-horizontal",
        range: "dataexport/excel/timelogs-analysis",
        customerDayView: "dataexport/excel/customer-overview"
      }
      return endpoints[action] || ""
    },
    getFilename(action) {
      let timestamp = moment().unix()
      const filenames = {
        excel: `Zeiterfassung_Anwesenheitsliste_${timestamp}.xlsx`,
        dayView: `Zeiterfassung_Stundenübersicht_${timestamp}.xlsx`,
        range: `Zeiterfassung_VonBisListe_${timestamp}.xlsx`,
        customerDayView: `Zeiterfassung_KundenTagesAnsicht_${timestamp}.xlsx`
      }
      return filenames[action] || ""
    },
    startDataDownload(data, contentType, filename) {
      let blob = new Blob([data], { type: contentType })
      let link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 0 14px !important
}

.with-divider {
  border-bottom: 0.5px solid #EBEDF2;
}
</style>